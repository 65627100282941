import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import { Box, Spacer } from '../components/Shared'

const Content = styled(Box)`
  h2 {
    color: ${({ theme }) => theme.colors.toreaBay};
    font-size: ${({ theme }) => theme.fontSizes[7]};
    margin-bottom: 2rem;
  }

  p {
    margin: 1rem 0;
    font-size: ${({ theme }) => theme.fontSizes[5]};
  }

  a:link {
    color: ${({ theme }) => theme.colors.toreaBay};
    background-color: transparent;
    font-weight: 500;
  }
`

const NotFoundPage = () => {
  const {
    wpMisc: {
      pageBuilder: { layouts },
    },
  } = useStaticQuery(graphql`
    query fetchNotFound {
      wpMisc {
        pageBuilder {
          layouts {
            ... on WpMisc_Pagebuilder_Layouts_NotFound {
              fieldGroupName
              content
              image {
                databaseId
                modified
                sourceUrl
                altText
                localFile {
                  id
                  absolutePath
                  name
                  relativePath
                  publicURL
                  base
                }
              }
            }
          }
        }
      }
    }
  `)
  const { image, content } = layouts[3]

  return (
    <Layout>
      <SEO title="404: Not found" />
      <Box
        display="flex"
        flexDirection={['column', 'column', 'row']}
        as="section"
        p={5}
        margin="0 auto"
        maxWidth={['95vw', '95vw', '1250px']}
        justifyContent="space-evenly"
        alignItems="center"
      >
        <img src={image.localFile.publicURL} />
        <Spacer my={3} display={['block', 'block', 'none']} />
        <Content
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </Box>
    </Layout>
  )
}

export default NotFoundPage
